import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import ArtworkCard from "../components/ArtworkCard";
import Hero from "../components/Hero";

import { CenterLayout, Section } from "../components/styled";

const IndexPage = () => {
    const [loading, setLoading] = useState(true);
    const [postList, setPostList] = useState(null);

    const postData = useStaticQuery(graphql`
        {
            allFile(
                filter: { sourceInstanceName: { eq: "posts" }, extension: { eq: "md" } }
                limit: 4
                sort: { fields: childMarkdownRemark___frontmatter___date, order: DESC }
            ) {
                edges {
                    node {
                        id
                        childMarkdownRemark {
                            frontmatter {
                                path
                                title
                                date
                                image {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                tags {
                                    tag
                                }
                            }
                            fields {
                                readingTime {
                                    text
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    useEffect(() => {
        //console.log(postData.allMarkdownRemark.edges);
        if (postData) {
            let content = postData.allFile.edges.map(({ node }) => node.childMarkdownRemark);
            // console.log(getPostList(postData.allFile.edges));
            setPostList(content);
        }
        setLoading(false);
    }, [postData]);

    // console.log(postList);
    return (
        <Layout title="Home">
            <Hero />
            <Section index={0} nopadding className="recents">
                <CenterLayout maxWidth={650} width={90}>
                    <span className="page-title">Recent Posts</span>
                    <div className="post-grid">
                        {loading ? (
                            <h1>Loading...</h1>
                        ) : postList ? (
                            postList.map((post, index) => {
                                return <ArtworkCard key={index} data={post} />;
                            })
                        ) : null}
                    </div>
                </CenterLayout>
            </Section>
        </Layout>
    );
};

export default IndexPage;
