import React from "react";
import { Section, CenterLayout } from "./styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const Hero = () => {
    const moonImage = useStaticQuery(graphql`
        query {
            file(relativePath: { regex: "/moon/" }, sourceInstanceName: { eq: "images" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED) 
                }
            }
        }
    `);
    // console.log("moonImage: ", moonImage);

    return (
        // <Section index={0} nopadding relative height={"25vw"}>
        //     <div className="hero-container">
        //         <div className="hero-name-container">
        //             <h1 className="title">Meetings With</h1>
        //             <h1 className="title">The Moon.</h1>
        //             <div className="wave-container">
        //                 <Wave color="#e8eaf0" />
        //             </div>
        //         </div>
        //         <div className="hero-images-container"></div>
        //     </div>
        // </Section>
        <Section className="hero">
            <CenterLayout>
                <div className="hero-content">
                    <div className="info">
                        <h1>Meetings With The Moon.</h1>
                        <span>Artwork and Photography by Sally Russell.</span>
                    </div>
                    <GatsbyImage image={moonImage.file.childImageSharp.gatsbyImageData} style={{width:200, height:200}} alt="" />
                </div>
            </CenterLayout>
        </Section>
    );
};

export default Hero;

// const Wave = ({ color }) => {
//     return (
//         //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 560" className="wave">
//         //       <path
//         //           fill="#e8eaf0"
//         //           fillOpacity="1"
//         //           d="M20,552.5c8.8-5,35-21.6,54-67.5c21.3-51.5,2.3-64,22-103c15.9-31.4,28.3-23.5,53-59
//         //  c29.1-41.8,15.9-58.6,46-121c17.8-36.9,37.9-63.1,51-80c21.9-28.3,40.1-35.7,60-65.2c15-22.4,23.1-43.3,27.4-57.3H0l-0.1,561H3
//         //  C3,560.5,13.3,556.3,20,552.5z"
//         //       />
//         //   </svg>

//         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 560" className="wave">
//             <path
//                 fill={color}
//                 fillOpacity="1"
//                 d="M20,552.5c45.7-27.1,58.8-84.9,66-118.5c9.5-44.6-0.3-41.5,8-65c21.6-61.3,77.7-52.1,107-107
//        c28.3-53-13.9-80.5,19-160c19.1-46.2,46.3-68.2,54.3-74.3c22.2-17,44.5-24.5,59.1-28.2H0l-0.1,561H3C6.9,559.1,13.1,556.6,20,552.5z"
//             />
//         </svg>
//     );
// };
