import React from "react";
import { Link } from "gatsby";
import { format } from "date-fns";
import { GatsbyImage } from "gatsby-plugin-image";

const ArtworkCard = ({ data }) => {
    const { title, date, image, path, tags } = data.frontmatter;
    const timeToRead = data.fields.readingTime.text;

    return (
        <div className={"artwork-card-wrapper"}>
            <Link to={path}>
                {/* {gallery && <img src={gallery[0].image} className="artwork-card-image" alt="gallery"/>} */}
                {image && (
                    <GatsbyImage image={image.childImageSharp.gatsbyImageData} className="artwork-card-image" alt="" />
                )}
                <div className="artwork-info-container">
                    <div>
                        <span className="artwork-title item">{title}</span>
                        <span className="date item">
                            {format(new Date(date), "MMM d, y")} • {timeToRead}
                        </span>
                        {/* <span className="item">{"beans"}</span> */}
                    </div>
                </div>
            </Link>
            {tags && (
                <div className="tags-flex">
                    {tags.map(({ tag }, index) => (
                        <div key={index} href="#" className="tag">
                            #<span>{tag}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

// ArtworkCard.propTypes = {
//     landscape: PropTypes.bool.isRequired,
//     imageLeft: PropTypes.bool.isRequired,
//     data: PropTypes.object.isRequired,
// };

export default ArtworkCard;

//<div className="artwork-card-image" style={{background: `url(${gallery[0].image}) center / cover`}}/>
